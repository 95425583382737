<template>
  <div class="pdp-product-details">
    <section id="buying-zone" class="buying-zone grid-container mb-6" data-pdp>
      <div class="grid-x grid-margin-x">
        <div class="buying-zone__carousel small-12 large-8 cell mb-2" :data-brand-name="product.brandNameFull">
          <input id="product-name" type="hidden" :value="product.name" />
          <input id="product-code" type="hidden" :value="variant.code" />
          <input id="product-brand" type="hidden" :value="variant.brandNameFull" />
          <!-- b2c Image Panel -->
          <pdp-product-image-panel
            v-if="hasImages"
            :product="product"
            :color="selectedColor"
            :max-thumbnails="options.maxPdpThumbnails"
          />

          <!-- CMS Consumer Message Slot Desktop -->
          <div v-if="viewBreakpointMoreThan('medium')">
            <slot name="consumer-messages"></slot>
          </div>
        </div>

        <div class="small-12 large-4 cell">
          <div class="grid-x grid-margin-x">
            <pdp-product-header :product="product" :options="options" :variant-code="variantCode">
              <!-- CMS Consumer Message Slot Tablet -->
              <template v-if="viewBreakpointIs('medium')" #consumer-messages>
                <slot name="consumer-messages"></slot>
              </template>
            </pdp-product-header>
            <footer class="buying-zone__footer small-12 medium-6 large-12 cell" data-buying-zone>
              <div class="my-2">
                <div class="flex flex-col mb-2">
                  <div class="buying-zone__price-wrapper">
                    <pdp-product-price
                      v-if="!product.isArchived && !options.hidePrices && variantCode"
                      :product="product"
                      :consumer="consumerPrice"
                    />
                  </div>
                </div>
                <div
                  v-if="copyPositioningStatement.length > 0"
                  class="buying-zone__positioning-statement mb-2 pt-2 border-t border-gray-10"
                >
                  <p qaid="product-positioning-statement" v-html="copyPositioningStatement" />
                </div>
                <pdp-product-summary :product="product" />
                <product-compare-button
                  v-if="options.productCompareEnabledOnSite && variantCode"
                  small
                  qaid="compareProductButton"
                  :product="product"
                />
              </div>
              <pdp-product-attributes-container
                ref="attributesContainer"
                v-model="variantCode"
                :product="product"
                :render-stock-status="!options.kioskModeEnabledOnSite && options.eCommAvailable"
                :stock-message="stockMessage"
                :init-attribute-selection="initAttributeSelection"
                @color-changed="
                  (color) => {
                    selectedColor = color;
                  }
                "
                @input="
                  (code) => {
                    variantCode = code;
                  }
                "
              />

              <pdp-build-a-bundle-cta
                v-if="showElectraAccessoryFinderCTA"
                class="cursor-pointer"
                :product="product"
                :variant-code="variantCode"
              />

              <pdp-pre-order v-if="hasFutureStock" :variant="variant" />

              <pdp-add-to-cart
                :product="product"
                :is-purchasable="isPurchasable"
                :variant-code="variantCode"
                :options="options"
                :message="cartSuccessMessage"
                :add-to-cart-button-caption="addToCartButtonCaption"
                :stock-message="stockMessage"
                :track-event-data="trackEventData"
                :is-locally-stock-available="isLocallyStockVariantAvailable"
                @add-to-cart="
                  (quantity) => {
                    addToCart(variantCode, quantity);
                  }
                "
              >
                <template v-if="isLocallyWidgetButtonEnabled" #locally-button>
                  <pdp-locally-product-finder
                    :variant="variant"
                    :variant-code="variantCode"
                    :product="product"
                    :options="options"
                    :show-locally-widget="showLocallyWidget"
                    @change-locally-widget="updateShowLocallyWidget"
                  />
                </template>

                <!-- CMS Consumer Message Slot Mobile -->
                <template v-if="viewBreakpointIs('small')" #consumer-messages>
                  <slot name="consumer-messages"></slot>
                </template>
              </pdp-add-to-cart>

              <pdp-customize-product-button
                v-if="!product.isArchived && !options.kioskModeEnabledOnSite"
                :project-one-url="projectOneUrl"
                :is-project-one-configurator-active="options.isProjectOneConfiguratorActive"
                :custom-configurator-type="product.customConfiguratorType"
                :custom-product-starting-point="product.customProductStartingPoint"
                :site-locale="options.siteLocale"
                :options="options"
              />

              <div v-if="product.buyingZoneCMSLink && product.buyingZoneCMSLink.length > 0">
                <pdp-buying-zone-cms-link
                  v-for="(cmsLink, i) in product.buyingZoneCMSLink"
                  :key="`cms-link-${i}`"
                  :options="cmsLink"
                />
              </div>
              <pdp-rpa-results
                v-if="
                  !options.kioskModeEnabledOnSite &&
                  options.rpaFunctionalityActive &&
                  !showLocallyWidget &&
                  !options.isLocallyActive &&
                  variantCode
                "
                :is-bike="isBike"
                :retailer-data="retailerData"
                :is-location-postal-code-correct="isLocationPostalCodeCorrect"
                :options="options"
                :product="product"
              />
            </footer>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import PdpProductHeader from '@/components/containers/pdp/b2c/PdpProductHeader.vue';
import PdpProductPrice from '@/components/containers/pdp/PdpProductPrice.vue';
import PdpProductSummary from '@/components/containers/pdp/PdpProductSummary.vue';
import ProductCompareButton from '@/components/compare/ProductCompareButton.vue';
import PdpCustomizeProductButton from '@/components/containers/pdp/PdpCustomizeProductButton.vue';
import PdpAddToCart from '@/components/containers/pdp/b2c/PdpAddToCart.vue';
import PdpBuyingZoneCmsLink from '@/components/containers/pdp/PdpBuyingZoneCmsLink.vue';
import PdpRpaResults from '@/components/containers/pdp/PdpRpaResults.vue';
import PdpProductImagePanel from '@/components/containers/pdp/PdpProductImagePanel.vue';
import PdpLocallyProductFinder from '@/components/containers/pdp/PdpLocallyProductFinder.vue';
import PdpProductAttributesContainer from '@/components/containers/pdp/PdpProductAttributesContainer.vue';
import PdpBuildABundleCta from '@/components/containers/pdp/PdpBuildABundleCta.vue';
import PdpPreOrder from '@/components/buyingzone/PdpPreOrder.vue';
import {ProductDetails} from '@/components/containers/pdp/utils/product-details';
import {AddToCart} from '@/components/containers/pdp/utils/add-to-cart';
import {InitProductAttributes} from '@/components/containers/pdp/utils/init-product-attributes';
import trackPdp from '@/mixins/tracking/pdp/track-pdp';
import Variant from '@/utils/variant-helpers';

export default {
  name: 'PdpProductDetails',
  components: {
    PdpRpaResults,
    PdpBuyingZoneCmsLink,
    PdpAddToCart,
    PdpCustomizeProductButton,
    ProductCompareButton,
    PdpProductSummary,
    PdpProductPrice,
    PdpProductHeader,
    PdpProductImagePanel,
    PdpProductAttributesContainer,
    PdpLocallyProductFinder,
    PdpBuildABundleCta,
    PdpPreOrder,
  },
  mixins: [ProductDetails, InitProductAttributes, AddToCart, trackPdp],

  data() {
    return {
      isConsumerPriceReady: false,
    };
  },

  computed: {
    ...mapState('pdp', ['category', 'electraAccessoryFinderToggle', 'pdpFunctionsMapping', 'isElectraBrand']),
    ...mapState('backend', ['currencyIso']),
    ...mapState('user', ['selectedRetailer']),
    ...mapGetters('viewSize', ['viewBreakpointIs', 'viewBreakpointMoreThan']),

    showElectraAccessoryFinderCTA() {
      return (
        this.variantCode &&
        this.isBike &&
        this.electraAccessoryFinderToggle &&
        this.pdpFunctionsMapping.ElectraAccessoryFinder &&
        this.isElectraBrand
      );
    },

    hasFutureStock() {
      return Boolean(this.variant?.futureStock);
    },

    isLocallyWidgetButtonEnabled() {
      return !this.options.kioskModeEnabledOnSite && this.options?.isLocallyActive;
    },
  },

  watch: {
    variantCode(code) {
      const productCode = code || this.product.code;
      this.setVariantPdpSku(productCode);

      const mechanicNotes = Variant(productCode, this.product).get()?.mechanicsNotes;
      this.setMechanicNotes(mechanicNotes);

      this.loadPricing(code);

      // Updates trackEventData size variants
      let variantData = {variantCode: code, productSize: this.productSizeText(code)};
      this.setTrackData(variantData);
    },
    consumerPrice(value) {
      this.isConsumerPriceReady = true;
      this.setVariantPrice(value);
      this.setTrackData({productPrice: value.displayPriceValue.calculable});
    },
    selectedRetailer: {
      handler(newVal, oldVal) {
        if (newVal?.name !== oldVal?.name) {
          this.fetchLocallyAvailableVariants();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.setIsECommAvailable(this.options.eCommAvailable);
    this.setIsLocallyActive(this.options.isLocallyActive);
  },

  methods: {
    ...mapActions('pdp', ['setVariantPdpSku', 'setVariantPrice', 'fetchLocallyAvailableVariants']),
    ...mapMutations('pdp', ['setIsECommAvailable', 'setIsLocallyActive', 'setMechanicNotes']),

    updateRpaResults(code, product) {
      this.$emit('updateRpaResults', code, product);
    },
  },
};
</script>
