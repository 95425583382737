<template>
  <div class="mb-2">
    <pdl-collapse-item>
      <template slot="title">
        <pdl-media-object class="align-middle">
          <template slot="media">
            <pdl-image :alt="integration.image.alt" :src="getIntegrationChannelImage" />
          </template>
          <template slot="content">
            <div>
              <pdl-heading level="4" size="md">{{ $t(integration.title) }}</pdl-heading>
              <pdl-label v-if="appIntegrationStatus" class="mt-1" kind="positive">
                {{ $t('rcp.integrations.connected') }}
              </pdl-label>
              <pdl-label v-else class="mt-1" kind="critical">{{ $t('rcp.integrations.notConnected') }}</pdl-label>
            </div>
          </template>
        </pdl-media-object>
      </template>
      <div class="flex">
        <div class="hidden md:block md:w-1/5 flex-none md ml-2" :style="getComputedWidth"></div>
        <div class="md:w-4/5 w-full">
          <pdl-image
            v-if="integration.showPoweredBy"
            :alt="integration.poweredByImg.alt"
            :src="integration.poweredByImg.url"
          />
          <div>{{ $t(integration.contentCopy) }}</div>
          <div class="my-3">
            <pdl-button
              v-if="appIntegrationStatus"
              button
              alert
              qaid="rcp-garmin-disconnect-button"
              @click="showDisconnectDialog"
            >
              {{ $t('garage.garmin.disconnect') }}
            </pdl-button>
            <pdl-button
              v-else
              button
              primary
              qaid="rcp-garmin-disconnect-cancel-button"
              :class="customButtonClass"
              @click="openAppSsoWindow"
            >
              {{ $t(integration.connectCopy) }}
              <pdl-image
                v-if="integration?.connectButtonImage?.url"
                :alt="integration?.connectButtonImage?.alt"
                :src="integration?.connectButtonImage?.url"
              />
            </pdl-button>
          </div>
        </div>
      </div>
    </pdl-collapse-item>
  </div>
</template>

<script>
import {PdlImage} from '@pedal/pdl-image';
import {PdlButton} from '@pedal/pdl-button';
import {PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlMediaObject} from '@pedal/pdl-media-object';
import {PdlHeading} from '@pedal/pdl-section-header';
import {PdlLabel} from '@pedal/pdl-label';
import {mapState, mapGetters} from 'vuex';
import {ViewSize} from '@/constants/view-breakpoints';

export default {
  components: {
    PdlButton,
    PdlImage,
    PdlCollapseItem,
    PdlMediaObject,
    PdlHeading,
    PdlLabel,
  },
  props: {
    integration: {
      type: Object,
      default: () => {},
    },
    appIntegrationStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('backend', ['cdnStaticFilesRoot']),
    ...mapGetters('viewSize', ['viewBreakpointLessThan']),
    imageUrl() {
      // replaces image src
      return `${this.cdnStaticFilesRoot}${this.integration.image.url}`;
    },
    customButtonClass() {
      return this.integration?.buttonCustomClass || '';
    },
    getIntegrationChannelImage() {
      const {url, smallScreenUrl} = this?.integration.image;
      return this.viewBreakpointLessThan(ViewSize.MEDIUM) ? smallScreenUrl : url;
    },
    getComputedWidth() {
      return {width: this.viewBreakpointLessThan(ViewSize.MEDIUM) ? 0 : '150px'};
    },
  },
  methods: {
    openAppSsoWindow() {
      this.$emit('open-app-sso-window', this.integration.app);
    },
    showDisconnectDialog() {
      this.$emit('show-app-disconnect-dialog', this.integration.app);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-strava,
.bg-strava:hover {
  background: #fc4c02;
}

.button-content-image {
  padding: 0;
  border: 0;
}
</style>
