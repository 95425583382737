const ThirdPartyApps = Object.freeze({
  GARMIN: 'GARMIN',
  WAHOO: 'WAHOO',
  STRAVA: 'STRAVA',
});
const AppsList = Object.values(ThirdPartyApps);

const AppConfigurations = Object.freeze({
  GARMIN: {
    app: ThirdPartyApps.GARMIN,
    title: 'text.account.garmin',
    contentCopy: `rcp.integration.garmin.description`,
    connectCopy: 'rcp.integrations.garmin.connect',
    disconnectDialogTitle: 'rcp.garmin.disconnect.confirm',
    image: {
      url: 'https://media.trekbikes.com/image/upload/w_150,h_60/garmin-logo',
      smallScreenUrl: 'https://media.trekbikes.com/image/upload/w_72,h_60/garmin-logo',
      alt: 'text.account.garmin',
    },
    messages: {
      disconnect: {
        success: 'rcp.garmin.disconnect.success',
        error: 'rcp.garmin.disconnect.error',
      },
      connect: {
        success: 'garage.integrations.connectedGarmin',
        error: 'garage.integrations.garmin.connect.error',
      },
    },
    successCallbackMsg: 'garmin-redirect',
    connectionStatusKey: 'isGarminConnected',
  },
  STRAVA: {
    app: ThirdPartyApps.STRAVA,
    title: 'rcp.integrations.strava',
    contentCopy: `rcp.integration.strava.description`,
    disconnectDialogTitle: 'rcp.integrations.strava.disconnect.confirm',
    additionalLinks: [
      {
        url: '',
        linkLabel: '',
      },
    ],
    image: {
      url: 'https://media.trekbikes.com/image/upload/w_150,h_60/strava-logo',
      smallScreenUrl: 'https://media.trekbikes.com/image/upload/w_72,h_60/strava-logo',
      alt: 'rcp.integrations.strava',
    },
    poweredByImg: {
      url: 'https://media.trekbikes.com/image/upload/w_150,h_100,c_pad/powered-by-strava-logo',
      alt: 'rcp.integrations.strava',
    },
    buttonCustomClass: 'bg-strava button-content-image',
    connectButtonImage: {
      url: 'https://media.trekbikes.com/image/upload/c_crop,w_185,h_40/connect-with-strava',
      alt: 'rcp.integrations.strava',
    },
    showPoweredBy: true,
    messages: {
      disconnect: {
        success: 'rcp.integrations.strava.disconnect.affirm',
        error: 'rcp.integrations.strava.disconnect.error',
      },
      connect: {
        success: 'rcp.integrations.strava.connect.success',
        error: 'rcp.integration.strava.connect.error',
      },
    },
    successCallbackMsg: 'garmin-redirect',
    connectionStatusKey: 'isStravaConnected',
  },
  WAHOO: {
    app: ThirdPartyApps.WAHOO,
    title: 'rcp.integrations.wahoo',
    contentCopy: 'rcp.integration.wahoo.description',
    connectCopy: 'rcp.integrations.wahoo.connect',
    disconnectDialogTitle: 'rcp.integrations.wahoo.disconnect.confirm',
    image: {
      url: 'https://media.trekbikes.com/image/upload/w_150,h_60/wahoo-logo',
      smallScreenUrl: 'https://media.trekbikes.com/image/upload/w_72,h_60/wahoo-logo',
      alt: 'rcp.integrations.wahoo',
    },
    messages: {
      disconnect: {
        success: 'rcp.integrations.wahoo.disconnect.affirm',
        error: 'rcp.integrations.wahoo.disconnect.error',
      },
      connect: {
        success: 'rcp.integrations.wahoo.connect.success',
        error: 'rcp.integration.wahoo.connect.error',
      },
    },
    successCallbackMsg: 'garmin-redirect',
    connectionStatusKey: 'isWahooConnected',
  },
});

export {ThirdPartyApps, AppConfigurations, AppsList};
