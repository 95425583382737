import {ConsumerProductPriceData} from '@/components/containers/pdp/utils/consumer-product-price-data';
import {ProductPriceData} from '@/components/containers/pdp/utils/product-price-data';
import {StockStatus} from '@/constants/stock-status';
import {stockDispatchActions} from '@/store/modules/product-stock-data';
import {retailerActions} from '@/store/modules/retailer-search';
import Variant from '@/utils/variant-helpers';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import {mapActions, mapMutations, mapState} from 'vuex';

const ProductDetails = {
  props: {
    product: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    copyPositioningStatement: {
      type: String,
      default: '',
      required: false,
    },
    isLocationPostalCodeCorrect: {
      type: Boolean,
      default: false,
      required: false,
    },
    retailerData: Object,
    stockMessage: Object,
  },

  watch: {
    variantCode(value) {
      const variant = Variant(value, this.product, this.b2b).get();
      //if locally disabled, load nearby retailer information
      if (!this.showLocallyWidget && !this.options.isLocallyActive) {
        if (!variant) return;
        const loadRetailerPayload = {
          variant: variant,
          inStockOnly: this.rpaViewInStock ?? false,
        };
        this.$store.dispatch(retailerActions.LOAD_RETAILER_DATA, loadRetailerPayload);
      }
      const stockStatus = !value ? StockStatus.NOT_AVAILABLE : variant?.globalStockStatus;
      this.$store.dispatch(stockDispatchActions.RETRIEVE_STOCK_MESSAGE, {stockStatus});
    },
  },

  computed: {
    ...mapState('backend', ['b2b', 'isConsumerFriendlyMode']),
    ...mapState('productBundle', ['buyingZoneQuantity']),
    ...mapState('pdp', ['rpaViewInStock']),

    variant() {
      return Variant(this.variantCode, this.product, this.b2b).get() ?? {};
    },

    isPurchasable() {
      return this.variant?.isPurchasable;
    },

    isLocallyStockVariantAvailable() {
      return this.variant?.isLocallyStockAvailable;
    },

    isBike() {
      return this.product?.productType === 'bike';
    },

    projectOneUrl() {
      return this.product.projectOneUrl ?? '';
    },

    hasImages() {
      return this.product.images && Object.keys(this.product.images).length > 0;
    },

    hasPrice() {
      if (!this.pricingData || !this.pricingData.retailerPrice) {
        return false;
      }
      const price =
        this.pricingData?.retailerPrice?.price?.formatted ?? this.pricingData?.retailerPrice?.price?.low?.formatted;
      return !isEmpty(price);
    },

    consumerPrice() {
      let pricingData = this.pricingData?.consumerPrice || this.product?.consumerPrice;
      if (!pricingData && this.isConsumerFriendlyMode) {
        return new ConsumerProductPriceData(this.populatePriceData(pricingData, []), {
          b2b: this.b2b,
        });
      }
      if (!pricingData || !Object.keys(pricingData).length) {
        return this.retailerPrice;
      }

      return new ConsumerProductPriceData(this.populatePriceData(pricingData, ['apple'], 'retailerPrice'), {
        b2b: this.b2b,
      });
    },

    retailerPrice() {
      let pricingData = this.pricingData?.retailerPrice ?? {};
      if (!pricingData || !Object.keys(pricingData).length) {
        pricingData = this.product?.retailerPrice ?? {};
      }

      return new ProductPriceData(
        this.populatePriceData(pricingData, ['advertised', 'saleAdvertised'], 'consumerPrice'),
        {b2b: this.b2b}
      );
    },
  },

  methods: {
    populatePriceData(target, keys, from) {
      if (!target) {
        target = {};
      }

      const priceData = !this.pricingData || !Object.keys(this.pricingData).length ? this.product : this.pricingData;
      keys.forEach((key) => {
        const itemData = priceData?.[from]?.[key];
        if (itemData) {
          target[key] = itemData;
        }
      });

      return target;
    },

    updateShowLocallyWidget(state) {
      this.showLocallyWidget = state;
      if (state) {
        this.$emit('loadRpaResults', this.variantCode);
      }
    },

    loadPricing: debounce(function (productCode) {
      if (!productCode) {
        this.pricingData = {};
      } else {
        const product = this.product.variants.find((variant) => variant.code === productCode);
        if (product) {
          this.pricingData = {
            retailerPrice: product.prices?.retailerPrice,
            consumerPrice: product.prices?.consumerPrice,
          };
        }
      }
    }, 10),

    getBuyingZoneBundleProductData(variantCode) {
      this.clearMainBundleProducts();
      this.setIsVariantCode(variantCode);
      this.setBundleMainProductName(this.product.name);
      this.setBundleMainProductQuantity(this.buyingZoneQuantity);

      const variantImage = document.querySelector('#heroCarousel .swiper-slide-active img');
      if (variantImage) {
        this.setBundleMainProductUrl(variantImage.src);
      }
    },

    ...mapMutations('productBundle', [
      'setIsVariantCode',
      'setBundleMainProductMetaData',
      'setBundleMainProductName',
      'setBundleMainProductUrl',
      'setBundleMainProductQuantity',
    ]),
    ...mapActions('productBundle', ['clearMainBundleProducts']),
  },

  data() {
    return {
      selectedColor: '',
      variantCode: '',
      pricingData: null,
      showLocallyWidget: this.options.isLocallyActive,
      selectedOption: null,
    };
  },
};

export {ProductDetails};
