<template>
  <section class="grid-container">
    <pdl-callout v-if="errorMessage" kind="error" qaid="user-exists-alert">
      <template #content>
        <span>{{ errorMessage }}</span>
      </template>
    </pdl-callout>

    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('myCompany.createNewUser') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <div class="grid-x grid-margin-x mb-6">
      <div class="cell medium-6 large-5 max-w-sm">
        <form ref="createUserForm" method="post" qaid="add-user-details-form" @submit="checkDuplicateUserAccount">
          <fieldset>
            <legend class="sr-only"></legend>
            <div class="form-group">
              <label for="first-name" class="form-label">{{ $t('profile.firstName') }} </label>
              <input
                id="first-name"
                v-model="firstName"
                type="text"
                name="firstName"
                qaid="first-name"
                required="required"
                class="form-control"
              />
            </div>
            <div class="form-group is-required">
              <label for="last-name" class="form-label"> {{ $t('address.surname') }}</label>
              <input
                id="last-name"
                v-model="lastName"
                type="text"
                name="lastName"
                required="required"
                qaid="last-name"
                class="form-control"
              />
            </div>
            <div class="form-group is-required">
              <label for="email-address" class="form-label"> {{ $t('text.address.email') }}</label>
              <input
                id="email-address"
                v-model="emailAddress"
                type="email"
                name="emailAddress"
                required="required"
                qaid="email-address"
                class="form-control"
              />
            </div>
          </fieldset>

          <fieldset class="form-fieldset">
            <input id="CSRFToken" type="hidden" :value="token" name="CSRFToken" />
            <legend class="form-fieldset__legend">{{ $t('b2busergroup.B2BRole') }}</legend>
            <label
              v-for="(item, index) in items.roles"
              :key="index"
              :qaid="`option-${index}`"
              :for="`option-${index}`"
              class="radio flex-wrap"
            >
              <input
                :id="`option-${index}`"
                v-model="b2bRole"
                type="radio"
                name="b2bUserRole"
                :value="item.id"
                :qaid="`option-${index}`"
                required="required"
              />
              <span class="control-indicator"></span>
              {{ $t(`${item.name}`) }}
              <span
                v-if="item.id === 'b2btrekuemployeepurchasegroup' && isTrekUEmployeePurchaseSelected"
                qaid="add-employee-purchase-treku"
                class="form-help ml-5"
              >
                {{ $t('myCompany.trekU.newUser') }}
              </span>
            </label>
          </fieldset>

          <fieldset v-if="items.rideClubRoles" class="form-fieldset">
            <legend class="form-fieldset__legend">{{ $t('b2busergroup.rideClubRole') }}</legend>
            <label
              v-for="(item, index) in items.rideClubRoles"
              :key="index"
              :qaid="`ride-club-option-${index}`"
              :for="`ride-club-option-${index}`"
              class="radio"
            >
              <input
                :id="`ride-club-option-${index}`"
                v-model="rideClubRole"
                type="radio"
                name="rideClubRole"
                :value="item.id"
                :qaid="`ride-club-option-${index}`"
                required="required"
                @change="onChange"
              />
              <span class="control-indicator"></span>
              {{ $t(`${item.name}`) }}
            </label>
          </fieldset>

          <fieldset
            v-if="items.hasOwnProperty('isDstAuthorized')"
            qaid="dst-fieldset"
            class="form-fieldset"
            :disabled="isTrekUEmployeePurchaseSelected"
          >
            <input id="CSRFToken" type="hidden" :value="token" name="CSRFToken" />
            <legend qaid="dst-legend" class="form-fieldset__legend strong">{{ $t('myCompany.dstPermission') }}</legend>
            <label qaid="dst-permission" class="checkbox">
              <input v-model="isDstAuthorized" qaid="dst-checkbox" type="checkbox" name="dstPermission" :value="true" />
              <span class="control-indicator"></span>
              <div qaid="dst-label">
                {{ $t(`custom.saved.user`) }}
              </div>
            </label>
          </fieldset>

          <div class="buttons">
            <pdl-button id="saveAddUserButton" primary qaid="save-add-button" type="submit">
              {{ $t('text.button.save') }}
            </pdl-button>
            <pdl-link id="cancelAddUserButton" button secondary :href="cancelButtonRef" qaid="cancel-add-button">
              {{ $t('text.cancel') }}
            </pdl-link>
          </div>
        </form>
      </div>
    </div>

    <pdl-dialog
      qaid="remove-user-account"
      :title="$t('myCompany.userAlreadyExists.trekU.existingStore')"
      :visible="isDialogVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="delete-treku-account-button" @click="submitCreateUserForm">
            {{ $t('text.headertext.conf') }}
          </trek-button>
          <trek-button secondary qaid="cancel-treku-account-deletion" @click="closeDialog">
            {{ $t('text.cancel') }}
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </section>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {useIsTranslationLoaded} from '@/composables/is-translation-loaded.js';
import {PdlDialog} from '@pedal/pdl-dialog';
import TrekButton from '@/components/TrekButton';
import {UserApi} from '@/api/user';

export default {
  components: {PdlButton, PdlLink, PdlCallout, PdlSectionHeader, PdlHeading, PdlDialog, TrekButton},
  props: {
    items: {
      type: Object,
      default: () => null,
    },
    token: {
      type: String,
      default: '',
    },
    cancelButtonRef: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      b2bRole: '',
      rideClubRole: '',
      selected: '',
      lastName: '',
      firstName: '',
      emailAddress: '',
      systemDown: '',
      userAlreadyExistsEmail: '',
      errorMessage: '',
      isDstAuthorized: false,
      isDialogVisible: false,
      errorProperty: '',
    };
  },

  computed: {
    isTrekUEmployeePurchaseSelected() {
      return this.b2bRole === 'b2btrekuemployeepurchasegroup';
    },
  },

  created() {
    if (!this.items) return;
    this.b2bRole = this.items.selectedRole;
    this.isDstAuthorized = this.items.isDstAuthorized;
    this.rideClubRole = this.items.selectedRideClubRole;
    this.lastName = this.items.lastName;
    this.firstName = this.items.firstName;
    this.emailAddress = this.items.emailAddress;
    if (this.items.userAlreadyExists) {
      this.userAlreadyExistsEmail = this.items.emailAddress;
      this.errorProperty = this.items.userAlreadyExists;
    }
    this.systemDown = this.items.systemDown;
    if (!this.userAlreadyExistsEmail && !this.systemDown) return;

    useIsTranslationLoaded(() => {
      if (this.userAlreadyExistsEmail) {
        this.errorMessage =
          this.errorProperty === 'myCompany.userAlreadyExists'
            ? this.$t('myCompany.userAlreadyExists', [this.userAlreadyExistsEmail])
            : this.$t(this.errorProperty);
      } else {
        this.errorMessage = this.$t('text.systemDown.cannotSubmit');
      }

      return this.errorMessage;
    });
  },

  methods: {
    async checkDuplicateUserAccount(e) {
      if (!this.isTrekUEmployeePurchaseSelected) {
        return;
      }

      e.preventDefault();
      try {
        const response = await UserApi.checkForDuplicateTrekUUserAccount(this.emailAddress);
        if (response?.data) {
          this.isDialogVisible = true;
        } else {
          this.submitCreateUserForm();
        }
      } catch (error) {
        console.log(error);
      }
    },

    submitCreateUserForm() {
      this.$refs.createUserForm.submit();
    },

    closeDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>
