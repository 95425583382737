<template>
  <article class="bg-white p-2 space-y-2">
    <pdl-label v-if="isSelected" kind="prominent">{{ $t('fulfillment.retailer.selected') }}</pdl-label>
    <pdl-heading class="retailer-card__heading" size="sm" qaid="retailer-card-name">
      {{ retailer.displayName }}
    </pdl-heading>
    <pdl-subheading v-if="hasStock" qaid="retailer-in-stock-message" class="has-stock-text">
      {{ $t('fulfillment.retailer.inStock', [retailer.retailerStock]) }}
    </pdl-subheading>
    <div qaid="retailer-card-body" class="text-xs">
      <p v-if="retailer.formattedDistance">
        {{ retailer.formattedDistance }} {{ $t('fulfillment.retailer.distance') }}
      </p>
      <p v-if="closingTime" class="mb-1">
        {{ $t('fulfillment.storePickup.selectedRetailer.openUntil', [closingTime]) }}
      </p>
      <p v-if="retailerAddress">{{ retailerAddress }}</p>
    </div>
    <div v-if="isDealerDelivery" qaid="retailer-delivery" class="flex items-center gap-x-1 home-delivery-text">
      <pdl-icon name="check_circle" size="18" />
      <span>{{ $t('text.retailer.delivers') }}</span>
    </div>
    <div class="buttons buttons--right">
      <pdl-button primary small qaid="retailer-card-pick-up" @click="setSelectedRetailer(retailer)">
        {{ $t('pickup.here.button') }}
      </pdl-button>
      <pdl-link button small secondary :href="retailer.url" qaid="retailer-card-details" style="flex-grow: 1">{{
        $t('storeDetails.title')
      }}</pdl-link>
    </div>
  </article>
</template>

<script>
import {PdlLabel} from '@pedal/pdl-label';
import {PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {PdlIcon} from '@pedal/pdl-icon';
import dayjs from 'dayjs';

export default {
  components: {PdlLabel, PdlButton, PdlLink, PdlHeading, PdlIcon, PdlSubheading},
  props: {
    retailer: {
      type: Object,
      default: () => ({}),
    },
    isSelected: {
      type: Boolean,
      default: () => false,
    },
    setSelectedRetailer: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    retailerAddress() {
      return this.retailer?.address?.formattedAddress;
    },
    isDealerDelivery() {
      const deliveryMiles = Number(this.retailer.deliveryMiles);
      const distanceMiles = this.retailer.distanceKm * 0.621371;

      return this.retailer.dealerDeliveryParticipation && distanceMiles <= deliveryMiles ? true : false;
    },
    closingTime() {
      const closingHour = this.retailer?.openingHours?.weekDayOpeningList.find(
        (listItem) => listItem.weekDay === dayjs().format('ddd')
      )?.storeClosingTime?.hourOfDay;

      if (!closingHour) return;

      const computedTime = dayjs().hour(closingHour);
      return computedTime.format('h A');
    },
    hasStock() {
      return !!this.retailer?.retailerStock;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-delivery-text {
  color: var(--green-140);
}

::v-deep .has-stock-text {
  color: var(--green-140);
}

::v-deep .button.button--primary {
  flex-grow: 1;
}

::v-deep .retailer-card__heading {
  @apply font-body;
  @apply font-bold;
}
</style>
