import RcEventsApi from '@/rideClubEvents/utils/rc-events-api.js';

const rcEvents = {
  namespaced: true,
  state: {
    eventsList: [],
    activeEvent: {},
    isEventLoading: false,
    isEventDeleteDialogOpen: false,
    hasUnsavedChanges: false,
  },
  mutations: {
    setEventsList(state, payload) {
      state.eventsList = payload;
    },
    setIsEventLoading(state, payload) {
      state.isEventLoading = payload;
    },
    setHasUnsavedChanges(state, payload) {
      state.hasUnsavedChanges = payload;
    },
    setActiveEvent(state, payload) {
      state.activeEvent = payload;
    },
    setDeleteDialogStatus(state, payload) {
      state.isEventDeleteDialogOpen = payload;
    },
  },
  actions: {
    async fetchEvent({commit}, eventId) {
      try {
        commit('setIsEventLoading', true);
        let response = await RcEventsApi.fetchEvent(eventId);
        if (!response.searchScope) {
          let searchScope = {
            locationPoint: {
              lat: response.location.geolocation.geometry.coordinates[1],
              lng: response.location.geolocation.geometry.coordinates[0],
            },
          };
          response = {...response, searchScope};
        }
        commit('setActiveEvent', response);
        commit('setIsEventLoading', false);
      } catch (error) {
        commit('setIsEventLoading', false);
        throw error.cause.data;
      }
    },
    clearActiveEvent({commit}) {
      commit('setActiveEvent', {});
    },
  },
};

export default rcEvents;
