import { render, staticRenderFns } from "./IntegrationsListItem.vue?vue&type=template&id=436bee5b&scoped=true&"
import script from "./IntegrationsListItem.vue?vue&type=script&lang=js&"
export * from "./IntegrationsListItem.vue?vue&type=script&lang=js&"
import style0 from "./IntegrationsListItem.vue?vue&type=style&index=0&id=436bee5b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436bee5b",
  null
  
)

export default component.exports